.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}



div {
  height: 100%;
  width: 100%;
}

.flex {
  display: flex;
}

.flex-column {
  display: flex;
  flex-direction: column;
}

.flex-row {
  display: flex;
  flex-direction: row;
}

.justify-left {
  justify-content: left;
}

.justify-center {
  justify-content: center;
}

.justify-right {
  justify-content: right;
}

.justify-between {
  justify-content: space-between;
}

.square-h {
  width: auto;
  height: 100%;
  aspect-ratio: 1 / 1;
}

.square-w {
  width: 100%;
  height: auto;
  aspect-ratio: 1 / 1;
}

.expend-w {
  width: 100%;
}

.expend-h {
  height: 100%;
}

.x-left {
  margin-right: 0;
  text-align: left;
}

.x-midle {
  text-align: center;
}

.x-right {
  margin-left: 0;
  text-align: right;
}

.y-top {
  margin-top: 0;
  vertical-align: top;
}

.y-middle {
  align-items: center;
}

.y-bottom {
  margin-bottom: 0;
  vertical-align: middle;
}

svg {
  width: 100%;
  height: 100%;
}

button {
  cursor: pointer;
  border: 2px 2px 2px 2px;
  padding: 1px;
}
